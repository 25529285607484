@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Vazirmatn;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #333;
    position: absolute;
    top: 0;
    left: 0;
}

.nav-header {
    width: 100%;
    background-color: #333;
}

li {
    float: left;

}

li a {
    display: block;
    color: white;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
}

/* Change the link color to #111 (black) on hover */
li a:hover {
    background-color: #111;
}

.active {
    background-color: #04AA6D;
}

.center {
    margin-right: auto;
    margin-left: auto;
    text-align: center;
}

.splice {
    border-style: solid;
    border-color: white;
    border-radius: 8px;
    margin-top: 20px;
    display: table;
}

.rtl{
    direction: rtl;
}

body {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #192038;
}

.padding-logout {
    padding: 3px 14px !important;
    float: right !important;
    position: absolute !important;
    right: 0 !important;
    border-radius: 0 !important;
}

.padding {
    padding: 20px !important;
}

.margin {
    margin: 20px !important;
}

.half-width {
    width: 50%;
}

.mods {
    border-color: rgba(255, 255, 255, 0);
    border-left: rgba(255, 255, 255, 0.49);
    border-width: 1px;
    border-style: solid;
}

.padding-top {
    padding-top: 20px;
}

.padding-left {
    padding-left: 20px;
}

.white {
    color: #fff;
}

.force-white{
    color: #ffffff !important;
}


.full-width {
    width: 100% !important;
}

.half-width {
    width: 50% !important;
}

.eighty-width {
    width:80% !important;
}

#root {
    width: 100%;
}

.game-list {
    width: 100%;
    display: inline-block;
    padding: 20px;
    right: 0;
    background: #374778;
    text-align: center;
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.1);
    overflow: auto;
    scroll-margin: 100px;
    z-index: -1;
}

.fix-position {
    position: absolute;
    top: 55px;
}

.reward-wrapper {
    background-color: #7eee53;
    border-radius: 15px;
    display: inline-block;
    box-shadow: black;
    padding: 10px;
    margin: 10px 0;

}

.game-mode-list {
    background-color: #ffffff;
    border-radius: 1px;
    display: inline-block;
}

.game-list-widget {
    border-radius: 15px;
    width: 100%;
    display: inline-block;
    margin-top: 15px;
    user-select: text;
    box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.1);
    border: 2px solid rgb(255, 255, 255);
    padding: 15px;
}

.green-div{
    background-color: #04AA6D !important;
}

.game-list-card {
    cursor: pointer;
    border-radius: 15px;
    background-color: #ffffff;
    width: 100%;
    display: inline-block;
    margin-top: 15px;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
    box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.1);
    border-bottom: 2px solid rgb(0, 0, 0);
}

.game-list-card:hover {
    background-color: #66d090;

}

.tournament-card {
    border-radius: 15px;
    background-color: #66d090;
    width: 100%;
    display: inline-block;
    margin-top: 15px;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
    box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.1);
    border-bottom: 2px solid rgb(0, 0, 0);
}

.float-left {
    float: left;
    text-align: left;
}

.game-list-info {
    float: left;
    text-align: left;
    margin-left: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    vertical-align: middle;
    display: inline-block;
}

.game-list-img {
    width: 80px;
    height: 80px;
}

.game-icon {
    width: 128px;
    height: 128px;
}

.game-banner {
    width: 128px;
    height: 128px;
}


::selection {
    color: #fff;
    background: #5372F0;
}

.wrapper-without-color {
    width: 380px;
    padding: 20px;
    border-radius: 20px;
    text-align: center;
}

.wrapper {
    margin-left: auto;
    margin-right: auto;
    width: 380px;
    padding: 40px 30px 50px 30px;
    background: #374778;
    border-radius: 20px;
    text-align: center;
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.1);
}

.wrapper header {
    font-size: 20px;
    font-weight: 200;
}

.wrapper form {
    margin: 40px 0;
}

form .field {
    width: 100%;
    margin-bottom: 20px;
}

form .field.shake {
    animation: shake 0.3s ease-in-out;
}

@keyframes shake {
    0%, 100% {
        margin-left: 0px;
    }
    20%, 80% {
        margin-left: -12px;
    }
    40%, 60% {
        margin-left: 12px;
    }
}

form .field .input-area {
    height: 50px;
    width: 100%;
    position: relative;
}

form input {
    width: 100%;
    height: 100%;
    outline: none;
    padding: 0 20px;
    font-size: 18px;
    color: #fff;
    background: none;
    caret-color: #5372F0;
    border-radius: 20px;
    border: 1px solid #ffffff;
    border-bottom-width: 2px;
    transition: all 0.2s ease;
}


form input[type="text"] {
    background: #374778;
}

form .field input:focus,
form .field.valid input {
    border-color: #5372F0;
}

form .field.shake input,
form .field.error input {
    border-color: #dc3545;
}

.field .input-area i {
    position: absolute;
    top: 50%;
    font-size: 18px;
    pointer-events: none;
    transform: translateY(-50%);
}

.input-area .icon {
    left: 15px;
    color: #bfbfbf;
    transition: color 0.2s ease;
}

.input-area .error-icon {
    right: 15px;
    color: #dc3545;
}

form input:focus ~ .icon,
form .field.valid .icon {
    color: #5372F0;
}

form .field.shake input:focus ~ .icon,
form .field.error input:focus ~ .icon {
    color: #bfbfbf;
}

form input::placeholder {
    color: #bfbfbf;
    font-size: 17px;
}

form .field .error-txt {
    color: #7580A2;
    text-align: left;
    margin-top: 5px;
}

form .field .error {
    display: none;
}

form .field.shake .error,
form .field.error .error {
    display: block;
}

form .pass-txt {
    text-align: left;
    margin-top: -10px;
}

.wrapper a {
    color: #5372F0;
    text-decoration: none;
}

.wrapper a:hover {
    text-decoration: underline;
}

form input[type="submit"] {
    height: 50px;
    margin-top: 30px;
    color: #fff;
    padding: 0;
    border: none;
    background: #5372F0;
    cursor: pointer;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

form input[type="submit"]:hover {
    background: #2c52ed;
}

.profile-img {
    width: 55px;
    height: 55px;
}


.red-button {
    width: 100%;
    border-radius: 20px;
    height: 50px;
    margin-top: 30px;
    color: #fff;
    padding: 0;
    border: none;
    background: #f05353;
    cursor: pointer;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.red-button:hover {
    background: #ed2c2c;
}

.blue-button {
    width: 100%;
    border-radius: 20px;
    height: 50px;
    margin-top: 30px;
    color: #fff;
    padding: 0;
    border: none;
    background: #5372F0;
    cursor: pointer;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.blue-button:hover {
    background: #2c52ed;
}

.green-button {
    width: 100%;
    border-radius: 15px;
    height: 50px;
    margin-top: 30px;
    margin-right: auto;
    margin-left: auto;
    color: #045b22;
    padding: 0;
    font-size: 17px;
    border: none;
    background: #7ff053;
    cursor: pointer;
    border-bottom: 2px solid rgb(0, 0, 0);
    transition: all 0.3s ease;
}

.green-button:hover {
    background: #56ed2c;
}

.change-button {
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
    color: #ffffff;
    font-size: 17px;
    background: #ffffff00;
    cursor: pointer;
    transition: all 0.3s ease;
    border-style: dashed;
    border-color: #ffffff;
}

.change-button:hover {
    background: #56ed2c;
    color: #000000;
}

.change-name-input {
    width: 100%;
    color: #ffffff;
    border-style: dashed;
    background: #ffffff00;
    border-top: #ffffff;
    border-left: #ffffff;
    border-right: #ffffff;
}

.mode-input {
    width: 200px;
    color: black;
    border-style: dashed;
    display: block;
    float: left;
    background: #ffffff00;
    border-top: #ffffff;
    border-left: #ffffff;
    border-right: #ffffff;
}

.mode-label {
    margin-top: 100px;
}



.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: #2196F3;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.force-black {
    border-color: black !important;
    color: black !important;
}

.float-right td {
    margin: 100px;
    padding: 10px;
    text-align: right;
}

.sep-table {
    width: 100%;
    margin-top: 10px;
    border-radius: 10px;
    border: 2px solid black;
}

.dash-button {
    width: 100%;
    border-style: dashed;
    border-radius: 15px;
    height: 50px;
    margin-top: 30px;
    margin-right: auto;
    margin-left: auto;
    color: #ffffff;
    background-color: #ffffff00;
    border-color: #ffffff;
    padding: 0;
    font-size: 17px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.dash-button:hover {
    background-color: #c6d03a;
    color: #000000;
}

.float-right {
    float: right !important;
}

.select-blue-button {
    width: 150px;
    float: left;
    border-radius: 10px;
    color: black;
    cursor: pointer;
    border: 2px solid rgba(1, 1, 1, 1);
    transition: all 0.3s ease;
    height: 30px;
}

.select-blue-button:hover {
    background: #bbff00;
}

.select-btn {
    width: 150px;
    border-radius: 10px;
    color: black;
    cursor: pointer;
    border: 2px solid rgba(1, 1, 1, 1);
}

.select-btn:hover {
    background: #bbff00;
}

.remove-button {
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
    color: #ffffff;
    font-size: 17px;
    background: #ffffff00;
    cursor: pointer;
    transition: all 0.3s ease;
    border-style: dashed;
    border-color: #ffffff;
}

.remove-button:hover {
    background: #ff0000;
    color: #000000;
}


.unpublish-button {
    margin-right: 10px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
    font-size: 17px;
    cursor: pointer;
    transition: all 0.3s ease;
    color: #045b22;
    background: #f05353;
    border: 2px solid rgb(0, 0, 0);
}

.unpublish-button:hover {
    background: #ed2c2c;
}

.publish-button {
    margin-right: 10px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
    font-size: 17px;
    cursor: pointer;
    transition: all 0.3s ease;
    color: #045b22;
    background: #7ff053;
    border: 2px solid rgb(0, 0, 0);
}

.publish-button:hover {
    background: #56ed2c;
}

.notification-header{
    position: absolute;
    top: 0;
    float: right;
    text-align: right;
}